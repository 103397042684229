import React from 'react'
//import { useTranslation } from 'react-i18next';
//import mainVideo from '../../video/akyol.mp4';
import "./header.scss";
import {h1, h2, h3, h4, h5, h6, h7, h8, h9} from '../import';




function Header() {
  //const { t } = useTranslation()

  return (


     <div className='grid'> 
     <div className='grid-items'>  <img src={h1} alt="resim" /></div>
     <div className='grid-items'>  <img src={h2} alt="resim" /></div>
     <div className='grid-items'>  <img src={h3} alt="resim" /></div>
     <div className='grid-items'>  <img src={h4} alt="resim" /></div>
     <div className='grid-items'>  <img src={h5} alt="resim" /></div>
     <div className='grid-items'>  <img src={h6} alt="resim" /></div>
     <div className='grid-items'>  <img src={h7} alt="resim" /></div>
     <div className='grid-items'>  <img src={h9} alt="resim" /></div>
     <div className='grid-items'>  <img src={h8} alt="resim" /></div>
     

     
       {/*  <video className='video' autoPlay loop muted>
             <source src={mainVideo} type="video/mp4"/>
         </video> 
        <div className='slogan'>
         <div className='sloganlar'>
           <div className='grup'> 
         <div className='cizgi'>     </div>
           <div className='slogan1'>
             <p>{t('safDeri')}</p>
           </div>
           </div>
           <div className='grup'>
           <div className='cizgi2'>     </div>
           <div className='slogan2'>
             <p>{t('yüksekKapasite')}</p>
           </div>
           </div>
          
           <div className='grup'>
           <div className='cizgi3'>      </div>
           <div className='slogan3'>
             <p>{t('trendYapıcı')}</p>
           </div>
           </div>
           </div>
           
           <div className='text'>
           <div className='blok'>
             
             <p>
             <span>Misyon </span> 
             <br/> Tüm dünya pazarı için kalite deri kemer üretmek, tedarikçi ve müşterileriyle birlikte yüksek değer yaratmak.
             </p>
             
             <p>
             <span>Vizyon </span> 
             <br/>Deri Kemer Üretiminde dünya pazarı için üretici olmak. Müşterileri beklentilerini inovasyon ve kaliteli ürün ile sürdürülebilir olarak sağlamak.
           </p>
           </div>
           
          
            </div>

         </div> */}
   </div>
  
  )
  

}

export default Header