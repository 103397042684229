import React from 'react'
import { useTranslation } from 'react-i18next';
import "./contact.scss";

function Contact() {
  const { t } = useTranslation()
  return (
    <div className='contact'>
       <div className='map'>
           <iframe title="akyolkemer" width="100%" height="600" id="gmap_canvas" src="https://maps.google.com/maps?q=akyol%20kemer&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="100" marginwidth="100"></iframe>
       </div>
      <div className='adresmain'>

      <div className='fabrika'>
      <h4>{t('fabrika')}</h4>
      <hr />
            <p>{t('organize')}</p>
            <p>18.Cad.No:23 Çorum / TÜRKİYE</p>
            <p>{t('tel')}:+90 364 234 74 05</p>
            <p>{t('faks')}:+90 364 234 68 96</p>
            <br />
      </div>
      <div className='toptan'>
      <h4>{t('toptan')}</h4>
      <hr />
            <p>Ağaoğlu My Office 212 </p>
            <p>Mahmutbey Taşocoğı Yolu Cd. No:3 B Blok Kat:2</p>
            <p>Ofis:37 34218 Bağcılar / İstanbul / TÜRKİYE</p>
            <p>{t('tel')} & {t('faks')}:+90 212 522 25 79</p>
        </div>
       
       <div className='web'>  
        <br />
        <br />
        <br />
        <br />
           <hr />
            <p>www.akyolkemer.com</p>
            <a href="mailto:info@akyolkemer.com">info@akyolkemer.com</a>
            <br />
       </div>
        <br />
        <br />
      </div>
    </div>
    
  )
}

export default Contact