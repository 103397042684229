import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import 'bootstrap/dist/js/bootstrap.js'
import {BrowserRouter as Router} from 'react-router-dom';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['tr', 'en'],
    fallbackLng: 'tr',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

const loadingMarkup = (
  <div className="py-4 text-center">
    <h5>Loading...</h5>
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
         <Router fallback={loadingMarkup}>
            <App />
         </Router>
    
 
);