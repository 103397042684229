import React from 'react'
import './koleksiyon.scss';
import { Navigation } from "swiper";
import {mdl1, mdl2, mdl3, mdl4, mdl5, mdl6,mdl7, kol1, kol2, kol3} from './import';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import { useTranslation } from 'react-i18next';
import { Galeri } from './import';



export default function Koleksiyon() {
  const { t } = useTranslation()
  return (
    <>
    <Swiper
    navigation={true}
    modules={[Navigation]}
    className="swiper-slide"
    autoplay={true}
     
    >
      <SwiperSlide><img src={mdl1} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl2} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl3} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl4} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl5} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl6} alt="model" /></SwiperSlide>
      <SwiperSlide><img src={mdl7} alt="model" /></SwiperSlide>

    </Swiper>
    <div> 
     
      <div className='slogans'>
           <div className='grup'>
           <div className='slogan11'>
             <p>{t('yuzlerce')}</p>
           </div>
          
           <div className='slogan22'>
             <p>{t('kol-slogan')}</p>
           </div>
           </div>          
          
                     
      </div>
      <div className='resim'>
        <div> <img src={kol1} alt="showroom" /></div>
        <div> <img src={kol2} alt="showroom" /></div>
        <div> <img src={kol3} alt="showroom" /></div>
      </div>
      <div className='fon'>
         </div>
      <div className='showroom'>
        <p>SHOWROOM</p>
      </div>
      <div>
        <Galeri/>
      </div>


    </div>
  </>

  )
  
}

