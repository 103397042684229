import React, {useState} from 'react';
import logo from '../../assets/logow.png';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.scss';
import '../../index.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';



const Navbar= () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const { t } = useTranslation()



  return (
    <div className='navbar'>
      <div className='navbar-links'>
        <a href="/">
        <div className='navbar-links_logo'>
        <img src={logo} alt="akyolkemer-logo" />
        </div>
        </a>
       
        <div className='navbar-links_container'>
          <ul>
            <li>
              <Link to="/Kurumsal">{t('kurumsal')}</Link>
            </li>
            <li>
              <Link to="/Koleksiyon">{t('koleksiyon')}</Link>
            </li>
            <li>
              <Link to="/Referans">{t('referans')}</Link>
            </li>
            {/* <li>
              <Link to="/Guncel">{t('guncel')}</Link>
            </li> */}
            <li>
              <Link to="/Contact">{t('iletişim')}</Link>
            </li>
          </ul>{/* 
          <p><a href="#kurumsal">{t('kurumsal')}</a></p>
          <p><a href="#koleksiyon">{t('koleksiyon')}</a></p>
          <p><a href="#referans">{t('referans')}</a></p>
          <p><a href="#uretim">{t('üretim')}</a></p>
          <p><a href="#iletisim">{t('iletişim')}</a></p> */}
        </div>
      </div>
      <div className='navbar_menu'>
        {toggleMenu
        ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className='navbar_menu_container scale-up-center'>
            <div className='navbar_menu_container-links'>
            <ul>
            <li>
              <Link to="/Kurumsal">{t('kurumsal')}</Link>
            </li>
            <li>
              <Link to="/Koleksiyon">{t('koleksiyon')}</Link>
            </li>
            <li>
              <Link to="/Referans">{t('referans')}</Link>
            </li>
            {/* <li>
            <Link to="/Guncel">{t('guncel')}</Link>
            </li> */}
            <li>
              <Link to="/Contact">{t('iletişim')}</Link>
            </li>
          </ul>
{/*             <p><a href="#kurumsal">{t('kurumsal')}</a></p>
            <p><a href="#koleksiyon">{t('koleksiyon')}</a></p>
            <p><a href="#referans">{t('referans')}</a></p>
            <p><a href="#uretim">{t('üretim')}</a></p>
            <p><a href="#iletisim">{t('iletişim')}</a></p> */}

            </div>

           </div>
        )}
        
      </div>

    </div>
   
  )
  
}

export default Navbar;