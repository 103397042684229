import React from 'react';
import './footer.scss';
import {amphori, bsci, lgw, logogreen} from '../../components/import';
import { FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    
<footer className='footer'>
<div className='brand'>
  <img src={amphori} alt="amphori" />
  <img src={bsci} alt="bsci" />
  <img src={lgw} alt="lgw" />
</div>
  <div className='derux'> 
  <Link to="/Kvkk">KVKK</Link>
  <br />
  <a href="http://www.derux.com.tr/">© 2022 AkyolKemer. Powered by DERUX. All right reserved</a>
      
  </div>
<div className='akyol'>
    <img src={logogreen} alt="akyol" />
    <div className='bar'> </div>
    <div className='adres'>
    <p>O.S.B. 18. cadde No:23 ÇORUM / TÜRKİYE
      <br />
      00 90 364 234 74 05</p>
      <div className='phone'><FaPhoneAlt/> </div>
  
      </div>


</div>
</footer>
    
  )
}

export default Footer