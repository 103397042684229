import React from 'react'
import Headertwo from '../../components/header/Headertwo'
import { useTranslation } from 'react-i18next';
import { amphori, bsci, lgw, vector} from './import';
import './kurumsal.scss';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import { c1,c2,c3,c4,c5,c6,c7,c8 } from './import.js';


const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const items = [
      <div className="item" data-value="8">
      <img src={c8} alt="bina" />
    </div>,
  <div className="item" data-value="1">
      <img src={c1} alt="bina" />
    </div>,
    <div className="item" data-value="2">
      <img src={c2} alt="bina" />
    </div>,
    <div className="item" data-value="3">
      <img src={c3} alt="bina" />
    </div>,
    <div className="item" data-value="4">
      <img src={c4} alt="bina" />
    </div>,
    <div className="item" data-value="5">
      <img src={c5} alt="bina" />
    </div>,
      <div className="item" data-value="6">
      <img src={c6} alt="bina" />
    </div>,
      <div className="item" data-value="7">
      <img src={c7} alt="bina" />
    </div>,
  ];
  
  function Kurumsal() {
  const { t } = useTranslation()
  return (
    <div className='kurumsal'>
      <Headertwo/>
      <div className='first-section'>
        {/* <div className='title-div'>
        <div className='title'>
          <div>
          <img src={logo} alt="logo" />
          </div>
          <div>
          <h1>"25 yıllık tecrübe"</h1>
          </div>
        </div> 
        </div>*/}
        

        <div className='content'>
         
         <p>{t('tanitim')}</p>
        </div>
        <div className='prensip'>

          <div className='kutu' id='misyon'>
            <h1>{t('misyon')}</h1>
          <p>{t('misyon1')}</p>
            <img src={vector} alt="?" />
          </div>
          <div className='kutu' id='vizyon'>
            <h1>{t('vizyon')}</h1>
            <p>{t('vizyon1')}</p>
          <img src={vector} alt="?" />
          </div>
          <div className='kutu' id='faaliyet'>
            <h1>{t('faaliyet')}</h1>
            <p>{t('faaliyet1')}</p>
          <img src={vector} alt="?" />
          </div>
          <div className='kutu' id='sürdürülebilirlik'>
            <h1>{t('sürdürülebilirlik')}</h1>
            <p>{t('sürdürülebilirlik1')}</p>
          <img src={vector} alt="?" />
          </div>

        </div>
        
       
      </div>
      <div className='two-section'>

        <div className='alt-bolum'>

        <div className='brand2'>
            <img src={amphori} alt="amphori" />
            <img src={bsci} alt="bsci" />
            <img src={lgw} alt="lgw" />
        </div>
        </div>

        <section className="p-basic">
			<AliceCarousel 
      mouseTracking items={items} 
      responsive={responsive} 
      controlsStrategy="alternate"
      touchTracking
      keyboardNavigation />
			 
		</section>

      </div>
    </div>
  )
}

export default Kurumsal