import React from 'react';
import {aldi, lcw, tudors, waldush} from './import.js';
import './referans.scss';


const refsData = [ 
 {id: 1,},
 {id: 2,},
 {id: 3,},
 {id: 4,},
 {id: 5,},
 {id: 6,},
 {id: 7,},
 {id: 8,}
];

const Referans = () => (
 
    <div className='ref'>
     <div className='bg-image'> </div>
     <div className='refs'>
         {refsData.map((ref) => {
           return(
             <div className='grid_brand' key={ref.id} >
               <div className='grid-item'> 
               <img src={lcw} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={aldi} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={tudors} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={waldush} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={aldi} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={tudors} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={lcw} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={waldush} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={aldi} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={lcw} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={tudors} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={waldush} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={aldi} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={lcw} alt="brand" />
               </div>
               <div className='grid-item'> 
               <img src={waldush} alt="brand" />
               </div>
          
            

             </div>
              ) 
})}
     </div>
   </div>

);

export default Referans