import {kmr1, kmr2, kmr3, kmr4, kmr5, kmr6, kmr7,kmr8,kmr9,kmr11,kmr13, kmr12} from './import';
import React from 'react';
import './koleksiyon.scss';
import AliceCarousel from 'react-alice-carousel';



const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
  // 1440:{ items: 4 },
};

const urun = [
  <div className='item' data-value='1'>
    <img src={kmr1} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr3} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr2} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr5} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr4} alt="kemer" />
  </div>,
   <div className='item' data-value='1'>
    <img src={kmr12} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr6} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr7} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr9} alt="kemer" />
  </div>,
   <div className='item' data-value='1'>
    <img src={kmr13} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr8} alt="kemer" />
  </div>,
  <div className='item' data-value='1'>
    <img src={kmr11} alt="kemer" />
  </div>,

];
  const Galeri = () =>(
    <section className="basic">

    <AliceCarousel
    mouseTracking items={urun} 
    responsive={responsive} 
    controlsStrategy="all"
    touchTracking
    disableDotsControls
    keyboardNavigation={true}
    autoPlay
    animationDuration={800}
    infinite
   
    
    
    />
    </section>
    // <div className='grid-urun'>
    
    //      <div className='gr1'>
    //       <div className='grid-uruns'> <img src={kmr6} alt="kemer" /> </div>
         
    //       <div className='gr1-1'>
    //       <div className='grid-uruns'> <img src={kmr2} alt="kemer" /> </div>
    //       <div className='grid-uruns'> <img src={kmr4} alt="kemer" /> </div>
    //       </div>
    //       <div className='gr1'>
    //       <div className='grid-uruns'> <img src={kmr1} alt="kemer" /> </div>
    //       </div>
    //     </div> 
    //     <div className='gr2'>
    //     <div className='grid-uruns'> <img src={kmr8} alt="kemer" /> </div>
    //      <div className='gr-2-2'>

    //       <div className='grid-uruns'> <img src={kmr5} alt="kemer" /> </div>
    //       <div className='grid-uruns'> <img src={kmr7} alt="kemer" /> </div>
    //      </div>
    //       <div className='grid-uruns'> <img src={kmr3} alt="kemer" /> </div>
    //     </div>
       
      
    // </div>
  )

  export default Galeri