import React from 'react'
import mainVideo from '../../video/kurumsal.mp4';
import "./header.scss";



function Headertwo() {
    
  return (
    <div>
        <video className='video' autoPlay loop muted>
             <source src={mainVideo} type="video/mp4"/>
         </video> 
        {/* <div className='slogan'>
         <div className='sloganlar'>
           <div className='grup'> 
         <div className='cizgi'>     </div>
           <div className='slogan1'>
             <p>{t('safDeri')}</p>
           </div>
           </div>
           <div className='grup'>
           <div className='cizgi2'>     </div>
           <div className='slogan2'>
             <p>{t('yüksekKapasite')}</p>
           </div>
           </div>
          
           <div className='grup'>
           <div className='cizgi3'>      </div>
           <div className='slogan3'>
             <p>{t('trendYapıcı')}</p>
           </div>
           </div>
           </div>
           
           <div className='text'>
           <div className='blok'>
             
             <p>
             <span>Misyon </span> 
             <br/> Tüm dünya pazarı için kalite deri kemer üretmek, tedarikçi ve müşterileriyle birlikte yüksek değer yaratmak.
             </p>
             
             <p>
             <span>Vizyon </span> 
             <br/>Deri Kemer Üretiminde dünya pazarı için üretici olmak. Müşterileri beklentilerini inovasyon ve kaliteli ürün ile sürdürülebilir olarak sağlamak.
           </p>
           </div>
           
          
            </div>

         </div>  */}
    </div>
  )
}

export default Headertwo