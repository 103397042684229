import React, { useEffect } from 'react'
import "./App.scss";
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames'
import {Navbar, Header, Footer} from './components';
import {Contact,Koleksiyon,Kurumsal,KVKK,Referans} from './container';
import {Routes,Route} from "react-router-dom";
//import { RiH1 } from 'react-icons/ri';



const languages = [
  {
    code: 'tr',
    name: 'TR',
    country_code: 'tr',
  },
  {
    code: 'en',
    name: 'EN',
    country_code: 'gb',
  },
  
]

function App() {

  const currentLanguageCode = cookies.get('i18next') || 'tr'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    
    <div className="App">  
      <Navbar/>  
        <Routes>
            <Route exact path='/' element={<Header/>} />
            <Route exact path='/Kurumsal' element={<Kurumsal/>} />
            <Route exact path='/Contact' element={<Contact/>} />
            <Route exact path='/Koleksiyon' element={<Koleksiyon/>} />
            <Route exact path='/Referans' element={<Referans/>} />
            <Route exact path='/Kvkk' element={<KVKK/>} />
            {/* <Route exact path='/Guncel' element={<Guncel/>} /> */}
      </Routes> 
    <Footer/>
      
       
      <div className="language-select"> 
        <div>
        <button className='lang'> 
              {languages.map(({ code, name, country_code }) => (
                <li key={country_code}>
                  <a href='/'
                    className={classNames( {
                      disabled: currentLanguageCode === code,
                    })}
                    onClick={() => {
                      i18next.changeLanguage(code)
                    }}  
                  >
                    <span
                        className={`${country_code}`}
                      style={{
                        opacity: currentLanguageCode === code ? 0.3 : 1,
                              }}
                    ></span>
                  {name} 
                  </a>
                </li>
              ))}
              </button>
              
        </div>
      </div>
     
        {/* <h1 className="font-weight-normal mb-3">{t('welcome_message')}</h1> */}
        {/* <p>{t('days_since_release', { number_of_days })}</p> */}
       
    </div>
    
  );
}

/* const Home = () => (
  <Home/>
)
 */
export default App;
